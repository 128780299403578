const NotFoundPage = () => {
  if (typeof window !== "undefined") {
    if (window.location.pathname.startsWith("/quote-me")) {
      return (window.location = "/tenants/app/quote")
    }

    if (window.location.pathname.startsWith("/faqs")) {
      return (window.location = "/help")
    }

    return (window.location = "/")
  }

  return null
}

export default NotFoundPage
